/* Existing Styles */

.table-container {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  overflow-y: auto;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.table-container.show {
  opacity: 1;
  transform: translateY(0);
}

.data-visualization-container table {
  width: 100%;
  border-collapse: collapse;
  font-family: 'Arial', sans-serif;
}

.data-visualization-container th, .data-visualization-container td {
  padding: 8px;
  border: 1px solid #dee2e6;
  text-align: left;
  vertical-align: middle;
}

.data-visualization-container th {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
}

.data-visualization-container td {
  font-size: 12px;
  color: #495057;
}

.data-visualization-container tr:nth-child(even) {
  background-color: #f9f9f9;
}

.data-visualization-container tr:hover {
  background-color: #e9ecef;
}

.chart-container {
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.chart-container.show {
  opacity: 1;
  transform: translateX(0);
}

.chart-container h3 {
  text-align: center;
  font-size: 1.1rem;
  color: #ffffff;
  margin: 15px 0;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-family: 'Arial', sans-serif;
  background-color: #000000;
  border-radius: 5px;
  border: 1px solid #222222;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  display: inline-block;
  margin: 0 auto;
}

.no-data-message {
  font-size: 18px;
  color: #666;
  text-align: center;
  padding: 20px;
  border: 1px dashed #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

/* Custom Legend Styles */
.custom-legend {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.legend-color {
  width: 20px;
  height: 5px;
  margin-right: 5px;
}

.legend-color.y-vol {
  background-color: #8884d8;
}

.legend-color.y-vol1 {
  background-color: #82ca9d;
}

@media (min-width: 768px) {
  .data-visualization-container table {
    width: 100%;
  }
}

.recharts-wrapper {
  width: 100% !important;
}

/* Grid styles for d3 chart */
.grid line {
  stroke: lightgrey;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}

.grid path {
  stroke-width: 0;
}

.container-wrapper {
  height: 100%;
  overflow: hidden;
}

.x-axis-label, .y-axis-label {
  font-size: 1rem;
  font-weight: bold;
}

.multi-select-container {
  margin: 20px 0;
}

/* Container and Item Styles */
.multi-select-container-horizontal {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.multi-select-item {
  flex: 1;
  background-color: #ffffff;
  border: 1px solid #333333;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, z-index 0.3s ease;
  position: relative;
  z-index: 1;
}

.multi-select-item:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
  z-index: 10;
}

/* Responsive behavior for smaller screens */
@media (max-width: 768px) {
  .multi-select-container-horizontal {
    flex-direction: column;
    gap: 20px;
  }

  .multi-select-item {
    width: 100%; /* Full width for mobile screens */
  }
}

.react-multi-select {
  width: 100%;
  border: 1px solid #333333;
  border-radius: 5px;
}

.react-multi-select .multi-select__control {
  border: none;
}

.react-multi-select .multi-select__control--is-focused {
  box-shadow: none;
}

.react-multi-select .multi-select__menu {
  border-radius: 5px;
  border: 1px solid #333333;
}

.react-multi-select .multi-select__option--is-selected {
  background-color: #333333;
  color: white;
}

.selected-items-list {
  margin-top: 10px;
}

.selected-items-list ul {
  list-style: none;
  padding: 0;
}

.selected-items-list li {
  background: #f0f0f0;
  margin-bottom: 5px;
  padding: 8px;
  border-radius: 5px;
  color: #333333;
  border-left: 3px solid #333333;
}

.selected-items-list li:hover {
  background: #e0e0e0;
}

.selected-items-list p {
  color: #666;
  font-style: italic;
}

/* Adjust the table height for mobile devices */
@media (max-width: 768px) {
  .table-container {
    max-height: 300px;
    overflow-y: scroll;
  }

  .container-wrapper {
    height: auto !important;
  }
}

.multi-select-header {
  padding-bottom: 10px;
}

@media (min-width: 768px) {
  .multi-select-item {
    max-width: 50%;
  }
}