/* General overlay layout */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; /* Full viewport height */
  display: flex;
  z-index: 1000;
  overflow: hidden;
  flex-wrap: nowrap; /* Prevent wrapping */
  background: white;
}

/* Left section for larger screens */
.overlay-left {
  width: 35%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7));
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 2rem;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  height: 100%; /* Ensure the left section takes full height */
}

/* Ensure the content inside .overlay-left is centered */
.overlay-left .overlay-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; /* Ensure the content takes full height */
}

/* Right section for larger screens */
.overlay-right {
  width: 65%;  /* Adjusted width to make more room */
  background: #ffffff; /* White background */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  padding: 4rem 2rem;
  box-sizing: border-box;
  height: 100vh; /* Ensure the right section takes full height */
  overflow: hidden;
  flex-direction: column;
  margin-left: auto; /* Align to the right */
  position: relative;
}

/* Centering the content inside the right div */
.overlay-right-content {
  max-width: 350px;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;

  text-align: left; /* Align text to the left */
  margin: auto; /* Ensures horizontal centering */
}

/* Right section content styling */
.overlay-right .logo {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
  color: #343a40; /* Darker shade for contrast */
}

.overlay-right .step-text {
  font-size: 0.875rem;
  color: #7d7d7d;
  margin-bottom: 1rem;
}

.overlay-right .main-text {
  font-size: 1.75rem;
  line-height: 1.4;
  color: #343a40;
  margin-bottom: 1.5rem;
  font-weight: 400;
}

.overlay-right .progress-bar {
  height: 2px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), #ccc);
  margin-top: 1rem;
  width: 80%;
}

/* Overlay content styling */
.overlay-content {
  padding: 2rem;
  max-width: 80%;
}

.overlay-content h1 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
}

.overlay-content p {
  font-size: 1.1rem;
  margin-bottom: 2rem;
  line-height: 1.5;
}

.highlight {
  color: #ff00ff;
  font-weight: bold;
}

.close-button {
  width: 100%;
  background-color: transparent;
  color: white;
  border: 1px solid white;
  padding: 8px 24px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
}

.close-button:hover {
  background-color: #1f4e8c;
  color: white;
  border-color: #1f4e8c;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Forecasting info styling */
.forecasting-info {
  max-width: 470px;
  text-align: left;
  height: auto;
}

.forecasting-info h2 {
  margin-bottom: 1.5rem;
  font-size: 2.2rem;
  font-weight: bold;
  color: #343a40;
  letter-spacing: 0.5px;
}

.forecasting-info p {
  margin-bottom: 1.2rem;
  font-size: 1.1rem;
  line-height: 1.6;
  color: #495057;
}

.forecasting-info:hover {
  transform: scale(1.02);
  transition: transform 0.3s ease;
}

.text-orange {
  color: orange;
  font-weight: bold;
}

.text-green {
  color: green;
  font-weight: bold;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .overlay {
    flex-direction: column;
    height: auto;
  }

  .overlay-left {
    width: 100%;
    height: 100vh; /* Black section covers full height on smaller screens */
    justify-content: center; /* Center content vertically */
  }

  .overlay-right {
    width: 100%;
    height: auto;
    padding-top: 2rem;
    display: none; /* Hide the right section on smaller screens */
  }

  .overlay-left .overlay-content {
    justify-content: center;
    align-items: center;
    height: 100%; /* Ensure content is centered vertically */
  }

  .overlay-content h1, .forecasting-info h2 {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
  }

  .overlay-content p, .forecasting-info p {
    font-size: 1rem;
  }

  .close-button {
    padding: 6px 20px; /* Adjust padding for smaller screens */
  }
}

@media (max-width: 576px) {
  .overlay-content h1, .forecasting-info h2 {
    font-size: 1.3rem;
  }

  .overlay-content p, .forecasting-info p {
    font-size: 0.9rem;
  }

  .close-button {
    font-size: 0.85rem;
    padding: 5px 18px;
  }
}

@media (max-width: 576px) {
  .frcg-wrap {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }

  main {
    margin: 0;
    padding: 0;
    width: 100vw !important;
    text-align: left;
    overflow: auto;
    height: auto;
    background: white;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  /* Ensure the content inside .overlay-left is centered */
  .overlay-left .overlay-content {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
  }

  .page-content, .top-page-holder, .frcg-Banner {
    position: relative;
    overflow: auto;
    width: 100%;
  }

  frcg-left {
    width: 100%;
  }

  frcg-right {
    width: 100%;
  }
}

/* Style for PredictXY text in the right div */
.right-header-text {
  font-family: 'Times New Roman', serif; /* Match the font family */
  font-size: 2.5rem; /* Match the font size */
  font-weight: bold;
  color: white; /* Use white color */
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), #1f4e8c); /* Gradient similar to the banner */
  padding: 10px 20px;
  text-align: center;
  margin-bottom: 1rem;
}
