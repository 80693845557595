.how-to-section {
  background-color: #f9f9f9;
}

.how-to-section h3 {
  margin-left: 5px;
  font-size: 16px;
  font-weight: 600;
}

.accordion-item {
  border: 1px solid #333;
  border-radius: 5px;
  overflow: hidden;
}

.accordion-header {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease, padding 0.3s ease;
}

.accordion-header:hover {
  background-color: #444;
}

.accordion-header.active {
  background-color: #1a1a1a;
}

.accordion-icon {
  font-size: 20px;
  font-weight: bold;
}

.accordion-content {
  padding: 15px 20px;
  color: black;
  font-size: 15px;
  line-height: 1.5;
  border-top: 1px solid #444;
}

.accordion-content strong {
  color: #ff00ff; /* Gold color for emphasis */
}

hr {
  border: none;
  height: 1px;
  background-color: #ddd;
  margin: 10px 0; /* Adjust the margin of the horizontal line as well */
}

.sample-dataset-link {
  color: #ff00ff; /* Magenta */
}

@media (max-width: 768px) {
  .accordion-item {
    border: none !important;
    border-radius: 0px !important;
  }
}
