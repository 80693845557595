/* Spinner.css */
.spinner-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; /* Ensures the spinner is on top */
}

.loading-spinner {
  border: 8px solid rgba(255, 255, 255, 0.3); /* Light transparent */
  border-top: 8px solid rgba(255, 255, 255, 0.8); /* More opaque for a cleaner effect */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1.5s linear infinite;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  background: rgba(0, 0, 0, 0.7); /* Black transparent background */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
