/* Full-width horizontal bar for the header with a gradient background */
.header-bar {
  width: 100%;
  background: linear-gradient(45deg, #6e48aa, #9d50bb, #ff6e7f, #bfe9ff); /* Gradient background */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px; /* Match the button height */
  margin-bottom: 20px; /* Add gap between the bar and the bottom banner */
  position: relative;
  z-index: 10; /* Ensure it stays above other content */
}

/* Container for the buttons */
.button-container {
  display: flex;
  gap: 0; /* Remove gap between the buttons */
  border: none;
}

/* Header button styles */
.header-button {
  background-color: transparent;
  color: white; /* White text */
  font-weight: 600; /* Slightly bold text */
  letter-spacing: 0.5px; /* Add letter spacing */
  padding: 0 24px; /* Horizontal padding */
  height: 52px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase; /* Make text uppercase */
  border: none; /* Remove border */
  box-shadow: none; /* Remove any unwanted shadow/border */
}

/* Hover effect */
.header-button:hover {
  background: rgba(255, 255, 255, 0.2); /* Subtle white overlay on hover */
  color: white; /* Keep the text white */
  transform: scale(1.1); /* Add slight zoom effect */
  box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.5); /* Add subtle glow effect */
}

/* Responsive styles for tablets */
@media (max-width: 768px) {
  .button-container {
    justify-content: flex-start; /* Align buttons to the left on smaller screens */
  }

  .header-button {
    font-size: 0.9rem;
    padding: 0 16px; /* Smaller padding */
    height: 52px;
  }
}

/* Responsive styles for phones */
@media (max-width: 480px) {
  .button-container {
    flex-wrap: wrap; /* Stack buttons vertically */
  }

  .header-button {
    width: 100%; /* Full-width buttons */
    padding: 10px;
    text-align: center;
    font-size: 0.9rem;
    box-shadow: none; /* Remove shadow for mobile */
  }
}
