/* General WIP overlay layout */
.wip-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1000;
  overflow: hidden; /* Ensure no overflow and no scrollbars */
}

.wip-left {
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7));
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 2rem;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.wip-content {
  text-align: center;
  padding: 2rem;
  max-width: 80%;
}

.wip-content h1 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
}

.wip-content p {
  font-size: 1rem; /* Reduced font size for tighter text */
  margin-bottom: 1rem; /* Reduced margin to tighten spacing */
  line-height: 1.3; /* Reduced line-height for less space between lines */
}

.wip-close-button {
  background-color: transparent;
  color: white;
  border: 1px solid white;
  padding: 8px 24px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
}

.wip-close-button:hover {
  background-color: #1f4e8c;
  color: white;
  border-color: #1f4e8c;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
