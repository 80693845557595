/* Default Sidebar Styles */
frcg-left {
  width: 28.8%;
  float: left;
  padding: 0 0 20px 0;
  border-right: 10px solid white;
  margin-right: -10px;
  background-color: white;
}

.frcg-module {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.235rem;
  overflow: auto;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 10px;
  background-color: white;
  cursor: pointer;
}

.frcg-module:hover, .frcg-module.active {
  background-color: #e0f7fa;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-color: #b0bec5;
}

.frcg-number {
  font-size: 36px;
  font-weight: bold;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.7);
  background: none;
}

.frcg-content {
  flex: 1;
  display: flex;
  justify-content: center;
  text-align: left;
  padding-left: 10px;
}

.frcg-upload-text {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
  margin: 0;
  align-self: flex-start;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  position: relative;
  padding-bottom: 3px;
}

.frcg-upload-text::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #1a73e8;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.frcg-module:hover .frcg-upload-text::after,
.frcg-module.active .frcg-upload-text::after {
  transform: scaleX(1);
}

.frcg-logo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 60px;
  background: none;
}

.frcg-upload-btn {
  width: 100%;
  border: none;
  background: none;
  padding: 0;
  transition: transform 0.3s ease, box-shadow 0.3s ease, opacity 0.3s ease;
}

.frcg-upload-btn img {
  width: 100%;
  object-fit: contain;
  filter: grayscale(100%);
}

.frcg-upload-btn:hover img,
.frcg-upload-btn:focus img {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  opacity: 0.8;
}

.error-message {
  color: red;
  margin-bottom: 0.5rem;
  text-align: left;
}

/* Sidebar for larger screens */
@media (min-width: 768px) {
  .data-visualization-mobile {
    display: none; /* Hide DataVisualization section for larger screens */
  }
}

/* Sidebar for small screens */
@media (max-width: 768px) {
  frcg-left {
    width: 100%;
    float: none;
    border-right: none;
  }

  .frcg-module {
    width: 100%;
    margin-bottom: 15px;
  }

  .data-visualization-mobile {
    display: block;
    width: 100%;
  }

  .data-visualization-mobile > div {
    margin: 20px 0;
  }
}

/* Ensure proper spacing and layout for DataVisualization in mobile */
.data-visualization-mobile {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  background-color: #f8f8f8;
}

.container-wrapper {
 
}
