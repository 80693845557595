.webkit.chrome.win body {
  -webkit-font-smoothing: antialiased;
}

body {
  font-size: 16px;
  font-size: 1.008rem;
  line-height: 22px;
  line-height: 1.43rem;
  background: white;
  font-family: "MuseoSansCond-700 !important";
  margin: 0;
  padding: 0;
  text-align: center;
  -webkit-font-smoothing: subpixel-antialiased;
  background-color: #ececec;
  color: #2c2825;
  text-align: center;
  background: #efefef;
  -moz-user-select: text;
  -webkit-user-select: text;
  user-select: text;
}

.frcg-wrap {
  width: 1099px;
  max-width: 1099px;
  margin: 0 auto;
  padding: 0;
  text-align: left;
}

main {
  margin: 0;
  padding: 0;
  width: 1099px;
  text-align: left;
  overflow: auto;
  height: auto;
  background: white;
  margin-bottom: 10px;
  margin-top: 10px;
}

frcg-right {
  float: right;
  width: 71.2%;
  padding: 0px 0 20px 0;
  margin: 0;
  background: white;
  overflow: visible;
}

h5 {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 700;
}

h5 {
  color: #e29000;
  font-family: MuseoSansCond-300;
  text-transform: uppercase;
  font-size: 18px;
  font-size: 1.134rem;
  line-height: 22px;
  line-height: 1.43rem;
  margin: 0 0 5px;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: normal;
}

p,
pre {
  margin: 1em 0;
}

a,
a:visited {
  color: #2c2825;
  text-decoration: none;
}

p.frcg-more {
  float: right;
  text-align: right;
  font-size: 14px;
  font-size: 0.882rem;
  line-height: 18px;
  line-height: 1.17rem;
}

section {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 20px;
}


.cnt-glance h3 {
  margin-top: 0;
}

section h3 {
  font-family: MuseoSansCond-500, Helvetica, sans-serif;
  color: rgba(0, 0, 0, 0.7);
  font-size: 26px;
  font-size: 1.638rem;
  line-height: 29px;
  line-height: 1.885rem;
  text-transform: none;
}

h3 {
  font-family: MuseoSansCond-500;
  color: rgba(0, 0, 0, 0.7);
  font-size: 22px;
  font-size: 1.386rem;
  line-height: 25px;
  line-height: 1.625rem;
  text-transform: none;
}

.cnt-glance ul {
  margin: 0 40px 20px 0;
  float: left;
  padding: 0;
  width: 45%;
}

section ul {
  padding-left: 20px;
}

menu,
ol,
ul {
  padding: 0 0 0 40px;
}

dl,
menu,
ol,
ul {
  margin: 1em 0;
}

section:after {
  content: "";
  display: table;
  clear: both;
}

.cnt-glance ul li {
  list-style-type: none;
  border-bottom: 1px solid #efefef;
  margin: 0 0 10px;
  padding-bottom: 5px;
}

ul li {
  margin-bottom: 10px;
}

.cnt-glance ul li span {
  color: #e29000;
}

cnt-glance ul li:last-of-type {
  border-bottom: none;
}

.cnt-glance ul:last-of-type {
  margin-right: 0;
}

.frcg-wrap h1.frcg-titlebar {
  background: rgba(0, 0, 0, 0.7);
  color: white;
}

h1.frcg-titlebar {
  margin: 0;
  background: #739FBF;
  display: block;
  text-align: left;
}

h1 {
  font-size: 40px;
  font-size: 2.52rem;
  line-height: 40px;
  line-height: 2.6rem;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-family: MuseoSansCond-700;
  color: white;
}

h1.frcg-titlebar img {
  width: 52px;
  height: 35px;
  float: left;
  margin: 0 15px 0 0;
  visibility: hidden;
}

.page-content,
.top-page-holder,
.frcg-Banner {
  position: relative;
  overflow: auto;
}

.frcg-anchors,
.flagship-content .frcg-anchors {
  padding: 20px 60px 14px 60px;
  font-size: 14px;
  font-size: 0.882rem;
  line-height: 22px;
  line-height: 1.43rem;
  overflow: auto;
  text-transform: uppercase;
  background-color: white;
}

.frcg-anchors ul:first-child,
.flagship-content .frcg-anchors ul:first-child {
  padding-left: 0;
}

.frcg-anchors ul,
.flagship-content .frcg-anchors ul {
  border-right: 1px solid #7d7d7d;
  list-style-type: none;
  float: left;
  margin: 0;
  padding: 0 15px;
}

.frcg-anchors ul li a,
.flagship-content .frcg-anchors ul li a {
  color: #000000;
  margin-bottom: 10px;
  font-family: MuseoSansCond-700;
  font-weight: normal;
}

.frcg-anchors ul li a:hover {
  color: #e29000;
}

.frcg-anchors ul li a:before {
  content: ' ';
  display: block;
  width: 0;
  height: 0;
  border-left: 0 solid transparent;
  border-right: 0 solid transparent;
  border-bottom: 0 solid #e29000;
  position: relative;
  top: -5px;
  transition: border-width 0.2s ease-in-out;
}

.frcg-anchors ul li a:hover:before {
  border-bottom-width: 4px;
}

.frcg-anchors ul:last-child {
  border-right: none;
}

.frcg-anchors {
  margin-bottom: 10px;
}

/* HowTo.css */
html {
  scroll-behavior: smooth;
}

.search-results, .rr-news, .rr-list, .langbar, .rr-intro, .glance, .latest-rr, .latest-country, .rr-media, .rr-reo, .interest-links, .journ-media, section>.blogs, .whatsnew, .fin-list, .data-intro, .data-content, .country-list, .search-info, .cnt-glance, .key-media {
  padding: 15px 0 15px 30px;
}

/* App.css */
.overlay-toggle-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  border-radius: 5px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.overlay-toggle-button:hover {
  opacity: 0.8;
}


