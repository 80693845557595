body {
    font-size: 16px;
    font-family: "MuseoSansCond-700 !important";
    background: white;
    margin: 0;
    padding: 0;
    text-align: center;
    background-color: #ececec;
    color: #2c2825;
    user-select: text;
}

.frcg-app {
    text-align: center;
}

.frcg-header {
    background-color: #004c97;
    color: white;
    padding: 20px;
}

.frcg-Banner {
    overflow: auto;
}

.frcg-main {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.frcg-module {
    display: flex;
    padding: 20px;
    border-bottom: 10px solid #efefef;
    font-size: 14px;
    overflow: auto;
    background-color: white;
}

.frcg-number {
    font-size: 72px;
    font-weight: bold;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #004c97;
    background-color: #ececec;
    margin-right: 20px;
}

.frcg-content {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0;
}

.frcg-upload-text {
    color: #e29000;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 5px;
    text-align: left;
}

.frcg-upload-btn {
    width: 100%;
    height: 100px;
    border: none;
    cursor: pointer;
}

.frcg-data-visualization-container,
.frcg-HowTo {
    padding: 15px 0;
}

.frcg-data-visualization-container table {
    width: 100%;
    border-collapse: collapse;
}

.frcg-data-visualization-container th, .frcg-data-visualization-container td {
    border: 1px solid #ddd;
    padding: 8px;
}

.frcg-data-visualization-container th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #4CAF50;
    color: white;
}
