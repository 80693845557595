/* Common Banner */
.common-banner {
  width: 30%;
  position: relative;
  display: flex; /* Use flexbox for vertical alignment */
  height: auto; /* Let the height adjust to the content */
  overflow: hidden; /* Prevent overflow */
}

/* Content Containers */
.faq-content,
.about-us-content {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex; /* Use flexbox for layout */
  flex-direction: row; /* Align items horizontally */
  align-items: stretch; /* Stretch items vertically to match the tallest one */
}

.common-content {
  margin-bottom: 20px;
  padding-top: 0px !important;
  flex: 7; /* Take 70% of the horizontal space */
  padding: 1rem; /* Add padding */
  background-color: #f9f9f9; /* Optional: Light background for the content area */
  color: #333; /* Text color */
  font-size: 15PX; /* Standard font size */
  line-height: 1.6; /* Improve readability */
  padding-right: 0px;
}

.common-content p {
  margin-bottom: 1rem; /* Add space between paragraphs */
}

/* Tilted Background */
.tilted-background {
  flex: 3; /* Take 30% of the horizontal space */
  background-color: rgba(0, 0, 0, 0.7); /* Black with transparency */
  color: white;
  padding: 4rem 2rem; /* Add vertical spacing */
  clip-path: polygon(0 0, 100% 0, 100% 92%, 0 100%); /* Tilted bottom */
  display: flex; /* Center content inside */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
}

.tilted-background-content {
  text-align: center; /* Center align text */
  color: #fff; /* White text */
}

.tilted-background-content h1 {
  font-size: 1.8rem; /* Smaller font size for more lines */
  font-weight: bold; /* Bold title */
  margin-bottom: 1rem; /* Space below the title */
  line-height: 1.4; /* Adjust line height for more spacing */
}

.tilted-background-content h1 .highlight {
  color: #ff00ff; /* Purple color for the highlighted word */
}

/* FAQ Content Padding */
.faq-content-padding {
  padding-bottom: 0px;
}

/* Responsive Design for Tablets */
@media (max-width: 768px) {
  .faq-content,
  .about-us-content {
    margin-top: 20px;
    margin-bottom: 0px;
    flex-direction: column; /* Stack vertically for smaller screens */
  }

  .about-us-content {
    padding: 1rem;
  }

  .common-content {
    margin-bottom: 0px;
    margin-top: 20px;
    padding-top: 0px !important;
    flex: 1; /* Take full width */
    padding-left: 0px;
  }

  .tilted-background {
    flex: 1; /* Take full width */
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%); /* Adjust tilt for tablets */
    padding: 3rem 1.5rem; /* Adjust padding */
  }

  .tilted-background-content h1 {
    font-size: 1.5rem; /* Smaller font size for more lines */
    line-height: 1.4; /* Maintain line height for readability */
  }

  .common-banner {
    width: 100%;
  }
}

/* Responsive Design for Phones */
@media (max-width: 480px) {
  .faq-content,
  .about-us-content {
    margin-top: 20px;
    margin-bottom: 0px;
    flex-direction: column; /* Stack vertically for small screens */
  }

  .about-us-content {
    padding: 1rem;
  }

  .common-content {
    margin-bottom: 0px;
    margin-top: 20px;
    padding-top: 0px !important;
    flex: 1; /* Take full width */
    padding-left: 0px;
  }

  .tilted-background {
    flex: 1; /* Take full width */
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%); /* Adjust tilt for tablets */
    padding: 3rem 1.5rem; /* Adjust padding */
  }

  .tilted-background-content h1 {
    font-size: 1.2rem; /* Smaller font size for more lines */
    line-height: 1.4; /* Maintain line height */
  }

  .common-banner {
    width: 100%;
  }
}
