.footer {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  width: 100%;
  font-size: 14px;
}

.colorful-background {
  background: linear-gradient(45deg, #6e48aa, #9d50bb, #ff6e7f, #bfe9ff); /* Colorful gradient */
  color: #ffffff; /* White text for better readability */
}

.footer-top {
  padding: 20px 0;
  border-bottom: 1px solid #ffffff; /* Light border for subtle separation */
}

.footer-bottom {
  background-color: rgba(0, 0, 0, 0.7); /* Keep the bottom part subtle */
  padding: 10px 0;
  text-align: center;
  color: #c7c7c7;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-links {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 20px;
}

.footer-links li a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease, transform 0.3s ease; /* Smooth color and scale transition */
}

.footer-links li a:hover, .footer-links li a:focus {
  color: #ffcc00; /* Bright color on hover for visibility */
  transform: scale(1.1); /* Slightly enlarges the link */
  text-decoration: underline; /* Adds underline to emphasize hover */
}

.text-muted {
  color: #c7c7c7 !important;
}

a, a:visited {
  color: white;
  text-decoration: none;
}
